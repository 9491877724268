import sk from './locales/sk'
import cz from './locales/cz'

const defaultLang = 'sk';
const htmlLang = document.documentElement.lang;

const translations = {
    sk: sk,
    cz: cz
};

export default function(key, locale) {
    return translations[locale || htmlLang][key] || translations[defaultLang][key] || null;
}

// Usage
// import l from 'translations/l'
// l('key') or l('key', 'locale')
