import $ from 'jquery' //remove jquery from this module
import l from './../l10n/l'

const isVatPayer = parseInt(document.querySelector('meta[name="is-vat-payer"]').content)

function reloadCartBar() {

    $.ajax({
        type: "GET",
        url: '/ajax/summaryCart'
    }).done(function(response) {
        response = fixResponse(response)
        var data = $.parseJSON(response);

        $('#ecommerce_header_count').html(data.total_items);
        const price = isVatPayer ? data.total_price : data.total_price_no_tax
        $('#ecommerce_header_price').html(price.replace('.', ','));

        if (data.total_items_count > 0) {
            // $('#ecommerce_header').addClass('open');
            // $('#ecommerce_header').find('#ecommerce-blink').addClass('ecommerce-blink');
            document.querySelector('#ecommerce_header').classList.add('open')
            document.querySelector('.navbar.navbar-main').classList.add('eshop')
            document.querySelector('.menu-observer').classList.add('hidden')
            document.querySelector('.menu-observer.eshop').classList.remove('hidden')

            // setTimeout(function() {
            //     $('#ecommerce_header').find('#ecommerce-blink').removeClass('ecommerce-blink');
            // }, 5000);
        } else {
            // $('#ecommerce_header').removeClass('open');
            document.querySelector('#ecommerce_header').classList.remove('open')
            document.querySelector('.navbar.navbar-main').classList.remove('eshop')
            document.querySelector('.menu-observer').classList.remove('hidden')
            document.querySelector('.menu-observer.eshop').classList.add('hidden')
        }
    });
}

function setPrice() {
	var price = $('#product_variant').find(':selected').data('price');
	var priceText = price.toFixed(2).toString().replace('.', ',') + ' €';
	$('#price-box').text(priceText);
}

function addToCart(product_id) {
    var variant_id = parseInt($('#product_variant').val());
    var val = parseInt($('#product_count').val());
    var gift = $('#product_gift').is(':checked');

    var data = {
        "product_id": product_id,
        "variant_id": variant_id ? variant_id : null,
        "quantity": !isNaN(val) ? val : 1,
        "gift": gift ? 1 : 0,
    };
    console.log(data);

    $.ajax({
        type: "POST",
        url: '/ajax/addToCart',
        data: data,
    }).done(function() {
        // $(window).scrollTop(0);
        reloadCartBar();
        // reloadCartIcon();


    });
}

function deleteCart(productId = '') {
    $.ajax({
        type: "POST",
        url: '/ajax/deleteCart/' + productId,
    }).done(function() {
        reloadCartBar();
        if(!viewingCart()) return
        orderCart();
        loadDescriptions();
    });

}

function viewingCart() {
    if(document.querySelector('tbody#cart-summary')) return true
}

function orderCart() {
    $.ajax({
        type: "GET",
        url: '/ajax/summaryCart'
    }).done(function(response) {
        //const jsonMatch = response.match(/\{.*\}/);
            response = fixResponse(response)

        var data = $.parseJSON(response);
        var parent = $('#cart-summary');

        parent.html('');
        $.each(data.items, function (index, row) {
            let imageURL = row.image_url ? row.image_url.replace('{size}', 'sm') : '';
            const base = 'https://www.moderneobce2.sk/data/'
            imageURL = base + imageURL
            
            const htmlImgBase = '<img style="height: 50px; width: 50px; margin-right: 4px;" src="'+ imageURL + '"' + 'alt="' + row.cart_product_name + '"/>'
            var html = '<tr class="product-row">';
            html += '<td style="padding-left: 0;">'+ '<div style="align-items: center; justify-content: left; display: flex; flex-direction: row">'+  htmlImgBase + '<div>'+ ' '+ row.cart_product_name + '</div>' +'</div>' +'</td>';
            html += '<td class="table-count" style="text-align: center; align-items: center;"><input type="number" value="'+ row.cart_quantity +'" class="input input-count" data-cart-id="'+ row.cart_id +'" style="max-width: 75px; text-align: center;"></td>';
            const price = isVatPayer ? row.cart_product_price : row.cart_product_price_no_tax
            html += '<td class="table-price" style="text-align: center; align-items: center;">'+ price.toFixed(2).replace('.', ',') +' €</td>';
            html += '<td class="table-price" style="text-align: right; padding-right: 0; align-items: center;" id="cart-id-total-price-'+ row.cart_id +'">'+ (row.cart_quantity * price).toFixed(2).replace('.', ',') +' €</td>';
            html += '<td class="delete-button" data-product-id=' + row.cart_product_id +'> <button><img src="/assets/images/ecommerce/x.png"></button> </td>';
            html += '</tr>';

             parent.append(html);
        });

        $('#cart-summary-total-price-no-tax').html(data.total_price_no_tax.replace('.', ','));
        $('#cart-summary-total-price').html(data.total_price.replace('.', ','));
		$('#cart-summary-total-price-with-delivery').html(data.total_price_with_delivery.replace('.', ','));
        // $('#cart-summary-cash-limit').html(data.cash_limit.replace('.', ','));
        // $('#cart-summary-cash-limit-desc').html(data.cash_limit.replace('.', ','));

        checkCod(data.total_price, parseFloat(data.cash_limit));

        $('#cart-modal').css({
            display: 'block'
        });

		initCountInputs();
        initDeleteButtons()
        
    });
}

function checkCod(totalPrice, cashLimit) {
	var disabled = true;

	if (parseFloat(totalPrice) > parseFloat(cashLimit) || disabled) {
		$('input[name="ecommerce_payment"][value="2"]').prop('checked', false);
		$('input[name="ecommerce_payment"][value="2"]').attr('disabled', true);
		$('#ecommerce_cash_on_delivery').attr('disabled', true);
	} else {
		$('input[name="ecommerce_payment"][value="2"]').attr('disabled', false);
		$('#ecommerce_cash_on_delivery').attr('disabled', false);
	}
}

function orderSubmit() {
    $.ajax({
        type: "POST",
        url: '/ajax/orderCart',
        data: {
            name: $('#ecommerce_name').val(),
            surname: $('#ecommerce_surname').val(),
            street: $('#ecommerce_street').val(),
            house_number: $('#ecommerce_house_number').val(),
            city: $('#ecommerce_city').val(),
            postal_code: $('#ecommerce_postal_code').val(),
            email: $('#ecommerce_email').val(),
            phone: $('#ecommerce_phone').val(),
            payment: $('input[name="ecommerce_payment"]:checked').val(),
            bank: $('input[name="ecommerce_bank"]:checked').val() || null,
            // i_consider: $('input[name="ecommerce_i_consider"]').is(':checked') ? 1 : 0,
            term_and_rights: $('input[name="ecommerce_term_and_rights"]').is(':checked') ? 1 : 0,
        }
    }).done(function(response) {
        var data = $.parseJSON(response);

        if (data.errors && data.errors.length > 0) {
            var parent = $('#ecommerce_errors');
    
            var html = '';
            $.each(data.errors, function(index, row) {
                html += '<li>' + row + '</li>';
            });
            parent.find('ul').html(html);
            parent.css({
                display: 'block'
            });
        } else {
            $('#cart-modal-message').find('.modal-card-foot').html('<button class="button is-success" onclick="orderClose()">'+ l('close') +'</button>');

            $('#cart-modal').css({
                display: 'none'
            });

            $('#ecommerce_errors').css({
                display: 'none'
            });

            $('#cart-modal-message').find('.modal-card-body').html(data.message);

            if (data.paymentByCard || data.paymentByBank) {
                window.paymentData = {
                    cid: data.paymentByCard.cid,
                    amnt: data.paymentByCard.amnt,
                    vs: data.paymentByCard.vs,
                    curr: data.paymentByCard.curr,
                    ru: data.paymentByCard.ru,
                    sign: data.paymentByCard.sign,
                    language: data.paymentByCard.language,
                    paymentmethod : data.paymentByCard.paymentmethod,
                    email: data.paymentByCard.email
                };

                if (data.paymentByCard) {
					var form = '<a onclick="loadBesteron(); orderClose();" class="button is-success">' + l('pay') + '</a>';
				}

				if (data.paymentByBank) {
					var form = '<a href="'+data.paymentByBank.url+'" onclick="orderClose();"  class="button is-success">' + l('pay') + '</a>';
				}

                $('#cart-modal-message').find('.modal-card-foot').html(form);
            }

            $('#cart-modal-message').css({
                display: 'block'
            });

            //reloadCartBar();

            $('#ecommerce_name').val('');
            $('#ecommerce_surname').val('');
            $('#ecommerce_street').val('');
            $('#ecommerce_city').val('');
            $('#ecommerce_postal_code').val('');
            $('#ecommerce_email').val('');
            $('#ecommerce_phone').val('');
            $('input[name="ecommerce_payment"]').prop('checked', false);
            $('input[name="ecommerce_i_consider"]').prop('checked', false);
            $('input[name="ecommerce_term_and_rights"]').prop('checked', false);
        }
    });
}

function orderClose() {
    $('#cart-modal').css({
        display: 'none'
    });

    $('#cart-modal-message').css({
        display: 'none'
    });

	$('#banks').css({
		display: 'none'
	});
}

$('.count-control-up').click(function() {
    var val = parseInt($('#product_count').val());
    $('#product_count').val(val + 1);
});

$('.count-control-down').click(function() {
    var val = parseInt($('#product_count').val());

    if (val > 1) {
        $('#product_count').val(val - 1);
    }
});

$('input[name="ecommerce_payment"]').change(function() {
	var val = parseInt($('input[name="ecommerce_payment"]:checked').val());

	if (val === 3) {
		$('#banks').show();
	} else {
		$('#banks').hide();
	}
});

function initCountInputs() {
	$('input[class$="input input-count"]').change(function () {
		var cartId = parseInt($(this).data('cart-id'));
		var val = parseInt($(this).val());

		if (!val || val < 1) {
			$(this).val(1);
			val = 1;
		}

		$.ajax({
			type: "POST",
			url: '/ajax/updateCartCount',
			data: {
				cartId: cartId,
				count: val,
			}
		}).done(function(response) {
			var data = $.parseJSON(response);

			$('#cart-id-total-price-' + cartId).text((isVatPayer ? data.product_price : data.total_price_exclude_tax).toFixed(2).replace('.', ',') +' €');
			$('#cart-summary-total-price-no-tax').text((data.total_price_exclude_tax).toFixed(2).replace('.', ','));
			$('#cart-summary-total-price').text((data.total_price_include_tax).toFixed(2).replace('.', ','));
			// $('#cart-summary-total-price-with-delivery').text((data.total_price_include_tax + 2.99).toFixed(2).replace('.', ','));

			// checkCod(data.total_price_include_tax, parseFloat(data.cash_limit));

			// reloadCartBar();
		});
	});
}

function loadDescriptions() {
    // const inputs = document.getElementsByName('delivery');

    // if(!inputs) {
    //     const inputs = document.getElementsByName('payment');
    // }

    const classA = Array.from(document.getElementsByName("delivery"))
         ,classB = Array.from(document.getElementsByName("payment"))
         ,inputs= Array.from(new Set(classA.concat(classB)))

    inputs.forEach(function (item) {
        let descContent = item.getAttribute('data-desc');
        let desc = document.createElement('p');
        desc.innerHTML = descContent;
        // let price = document.createElement('span')
        // price.innerHTML = item.getAttribute('data-price');
        let label = item.parentElement;
        // label.innerHTML += " - ";
        // label.insertAdjacentHTML('afterend', desc);
        label.insertAdjacentElement('afterend', desc);
        // label.appendChild(price);
        // label.innerHTML += "  €";

        // if(item.checked) {
        //     reloadFinalPrice(item);
        // }
    });

    // let lastRow = document.getElementsByName('send')[0].parentElement.parentElement.previousSibling;
    // lastRow.parentNode.insertBefore(document.getElementById('total-price-row'), lastRow);
}

// function reloadFinalPrice(input) {
//     var deliveryPrice = document.getElementById('delivery-price-hidden');
//     if(deliveryPrice == null)
//     {
//         deliveryPrice = 0;
//     }
//     else
//     {
//         deliveryPrice = parseFloat(deliveryPrice.innerHTML);
//     }

//     let oldTotalPriceCell = document.getElementById('old-total-price-cell');
//     let oldTotalPrice = oldTotalPriceCell.innerHTML;
//     let newTotalPrice = parseFloat(oldTotalPrice) + parseFloat(input.getAttribute('data-price')) + deliveryPrice;
//     oldTotalPriceCell.previousElementSibling.innerHTML = newTotalPrice;
// }

// function reloadCartIcon() {
//     let icon = document.getElementById('cart-icon');
//     let counter = document.getElementById('cart-icon-counter').firstChild.firstChild;

//     icon.classList.add('highlight');
//     // console.log(counter.innerHTML);
//     // let counterValue = parseInt(counter.innerHTML);
//     // console.log(counterValue)
//     if(counter == null)
//     {
//         let counter = document.getElementById('cart-icon-counter');
//         counter.innerHTML = 1;
//     }
//     else
//     {
//         counter.innerHTML = parseInt(counter.innerHTML) + 1;
//     }
// }

function clickRealSubmit() {
    let formValid = true;
    let scrollCount = 0;

    let checkbox = document.querySelector('input[name=ecommerce_term_and_rights]')
    checkbox.parentElement.classList.remove('red-attention')
    if (!checkbox.checked) {
        checkbox.parentElement.classList.add('red-attention')
        checkbox.parentElement.querySelector('a').classList.add('red-attention')
        formValid = false;
        checkbox.scrollIntoView({ behavior: 'smooth', block: 'center' })
        scrollCount++;
    }

    // let isCompanyCheckbox = document.querySelector('#ecommerce_is_company');
    // if(isCompanyCheckbox.checked) {
    //     let companyName = document.querySelector('#ecommerce_company_name');
    //     companyName.parentElement.parentElement.classList.remove('red-attention')
    //     if(!companyName.value) {
    //         companyName.parentElement.parentElement.classList.add('red-attention')
    //         formValid = false;
    //     }
    //
    //     let companyCin = document.querySelector('#ecommerce_company_cin');
    //     companyCin.parentElement.parentElement.classList.remove('red-attention')
    //     if(!companyCin.value) {
    //         companyCin.parentElement.parentElement.classList.add('red-attention')
    //         formValid = false;
    //     }
    // }

    for(const field of document.querySelectorAll('[data-is-mandatory]')) {
        if(!field.value.trim()) {
            console.log(field)
            formValid = false;
            field.parentElement.parentElement.classList.add('red-attention')
            if(!scrollCount) {
                field.scrollIntoView({ behavior: 'smooth', block: 'center' });
                scrollCount++;
            }
        }
    }

    if(formValid)
        document.querySelector('#frm-deliveryPaymentForm').submit()
}

function markMandatoryFields() {
    for(const id of document.querySelector('[data-mandatory-fields]')?.innerHTML.split(',') ?? []) {
        if(!id) {
            return;
        }
        const field = document.querySelector(`#${id}`)
        if(!field) {
            continue;
        }
        field.dataset.isMandatory = true;
        field.addEventListener('input', () => {
            if(!field.value.trim()) {
                return;
            }
            field.parentElement.parentElement.classList.remove('red-attention')
        })
    } 
}

function fixResponse(response) {
    if(!response.includes('Notice:')) return response
    const jsonSubstring = response.substring(
        response.indexOf("{") + 1, 
        response.lastIndexOf("}")
    )
    return "{" + jsonSubstring + "}"
}

function initDeleteButtons() {
    const deleteButtons = document.querySelectorAll('tbody#cart-summary td[data-product-id] button')
    deleteButtons.forEach((button) => {
        button.addEventListener('click', () => {
            deleteCart(button.parentElement.dataset.productId)
        })
    })
}

function productCountUp()
{
    var count = parseInt(document.getElementById('product_count').value);
    count++;
    document.getElementById('product_count').value = count;
}

function productCountDown()
{
    var count = parseInt(document.getElementById('product_count').value);
    if(count === 1)
        return;

    count--;
    document.getElementById('product_count').value = count;
}

window.addToCart = addToCart;
window.deleteCart = deleteCart;
window.orderCart = orderCart;
window.orderSubmit = orderSubmit;
window.orderClose = orderClose;
window.setPrice = setPrice;
window.initCountInputs = initCountInputs;
window.loadDescriptions = loadDescriptions;
// window.reloadFinalPrice = reloadFinalPrice;
window.clickRealSubmit = clickRealSubmit
window.initDeleteButtons = initDeleteButtons
window.productCountUp = productCountUp;
window.productCountDown = productCountDown;
window.markMandatoryFields = markMandatoryFields;
